import { SyntheticEvent, useEffect, useState } from 'react';
import { useDispatch } from 'src/store/store';
import { trackFootfallEvent } from 'src/utils/footfall';
import { Event, Section } from 'src/constants/footfall';
import { isUniqueImpression, addToUniqueImpressionsList, formatLink, getModalParams, getCtaType, getHelpScoutArticle, addToDismissList } from 'src/utils/featuredPosts';
import { Featured } from 'src/constants/types';
import { handleQueryParams } from 'src/modules/shared/modal/actions';
import { helpScoutArticle } from 'src/utils/helpScoutBeacon';
import NormalFeaturedPost from 'src/modules/community/shared/components/featuredPosts/normalFeaturedPost';
import StickyFeaturedPost from 'src/modules/community/shared/components/featuredPosts/stickyFeaturedPost';
import DismissFeedback from 'src/modules/community/shared/components/featuredPosts/dismissFeedback';
type Props = {
  featuredPost: Featured;
  isSticky?: boolean;
};
export default function FeaturedPost({
  featuredPost,
  isSticky = false
}: Props) {
  const dispatch = useDispatch();
  const [isDismissed, setIsDismissed] = useState(false);
  const {
    featuredPostId,
    callToActionUrl
  } = featuredPost;
  const formattedLink = formatLink(callToActionUrl);
  useEffect(() => {
    trackFootfallEvent(Event.ViewedFeaturedPost, {
      featuredPostId
    });
    if (isUniqueImpression(featuredPostId)) {
      trackFootfallEvent(Event.ViewedFeaturedPostUnique, {
        featuredPostId
      });
      addToUniqueImpressionsList(featuredPostId);
    }
  }, [featuredPostId]);
  const onDismiss = () => {
    setIsDismissed(true);
    addToDismissList(featuredPostId);
    trackFootfallEvent(Event.DismissedFeaturedPost, {
      featuredPostId
    });
  };
  const openModal = (link: string) => {
    const params = getModalParams(link);
    if (params) {
      const footfallSection = 'Campaign';
      dispatch(handleQueryParams(params, footfallSection));
    }
  };
  const openHelpScout = (url: string) => {
    const article = getHelpScoutArticle(url, featuredPostId);
    if (article) helpScoutArticle(article, {
      type: 'modal'
    });
  };
  const onClick = (e: SyntheticEvent) => {
    e.preventDefault();
    trackFootfallEvent(Event.ClickedFeaturedPost, {
      featuredPostId
    });
    const ctaType = getCtaType(callToActionUrl);
    switch (ctaType) {
      case 'modal':
        openModal(callToActionUrl);
        break;
      case 'helpscout':
        openHelpScout(callToActionUrl);
        break;
      case 'mailto':
        window.location.href = callToActionUrl;
        break;
      case 'oldModal':
        openModal(callToActionUrl);
        break;
      case 'link':
      default:
        window.open(formattedLink);
    }
  };
  const onStickyClick = (e: SyntheticEvent) => {
    e.preventDefault();
    trackFootfallEvent(Event.Clicked, {
      clickType: 'featured-post',
      clickMetadata: {
        section: Section.StickyFeaturedPostFooter
      }
    });
    onClick(e);
  };
  return <>
      {isDismissed ? <DismissFeedback featuredPostId={featuredPostId} /> : isSticky ? <StickyFeaturedPost featuredPost={featuredPost} onClick={onStickyClick} onDismiss={onDismiss} /> : <NormalFeaturedPost featuredPost={featuredPost} onClick={onClick} onDismiss={onDismiss} />}
    </>;
}